import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import Breadcrumbs from 'components/shared/breadcrumbs'

import Description from 'components/pages/door/description'
import ContactSectionDoor from 'components/contact-section-door'
import Extras from 'components/pages/sliding-doors/extras'

import { SlidingDoorsExtrasPageQuery } from 'types/graphql'

const Dodatki: React.FC<PageProps<SlidingDoorsExtrasPageQuery>> = ({
  data,
}) => {
  const PAGE = data?.wpPage?.slidingDoorsExtrasPage!
  const PAGE_SEO = data?.wpPage?.seo!

  const EXTRAS =
    PAGE?.slidingDoorsExtras?.map((item) => ({
      name: item?.slidingDoorsExtrasItemName!,
      image: {
        src: item?.slidingDoorsExtrasItemImage?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: item?.slidingDoorsExtrasItemImage?.altText!,
      },
      headings:
        item?.slidingDoorsExtrasItemHeadings?.map(
          (heading) => heading?.slidingDoorsExtrasItemHeading!
        ) || [],
      elements:
        item?.slidingDoorsExtrasItemElements?.map((element) => ({
          values:
            element?.slidingDoorsExtrasItemElementsValues?.map(
              (value) => value?.slidingDoorsExtrasItemElementsValue!
            ) || [],
        })) || [],
    })) || []

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Breadcrumbs
        spacing
        crumbs={[
          { name: 'Drzwi przesuwne', to: '/drzwi-przesuwne' },
          { name: 'Dodatki' },
        ]}
      />
      <Description
        heading={PAGE?.slidingDoorsExtrasIntroHeading!}
        text={PAGE?.slidingDoorsExtrasIntroParagraph!}
        image={{
          src: PAGE?.slidingDoorsExtrasIntroImage?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.slidingDoorsExtrasIntroImage?.altText!,
        }}
      />
      <Extras extras={EXTRAS} />
      <ContactSectionDoor
        title={PAGE?.slidingDoorsExtrasContactHeading!}
        text={PAGE?.slidingDoorsExtrasContactParagraph!}
        phone={PAGE?.slidingDoorsExtrasContactPhone!}
        email={PAGE?.slidingDoorsExtrasContactEmail!}
        initialFormMessage={PAGE?.slidingDoorsExtrasContactInitialFormMessage!}
      />
    </Layout>
  )
}

export default Dodatki

export const query = graphql`
  query SlidingDoorsExtrasPage {
    wpPage(slug: { regex: "/dodatki-do-drzwi-przesuwnych/" }) {
      seo {
        ...WpSEO
      }
      slidingDoorsExtrasPage {
        slidingDoorsExtrasIntroHeading
        slidingDoorsExtrasIntroParagraph
        slidingDoorsExtrasIntroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
        }
        slidingDoorsExtras {
          slidingDoorsExtrasItemName
          slidingDoorsExtrasItemHeadings {
            slidingDoorsExtrasItemHeading
          }
          slidingDoorsExtrasItemElements {
            slidingDoorsExtrasItemElementsValues {
              slidingDoorsExtrasItemElementsValue
            }
          }
          slidingDoorsExtrasItemImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
        slidingDoorsExtrasContactHeading
        slidingDoorsExtrasContactParagraph
        slidingDoorsExtrasContactPhone
        slidingDoorsExtrasContactEmail
        slidingDoorsExtrasContactInitialFormMessage
      }
    }
  }
`
