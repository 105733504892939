import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'

import media from 'styles/media'

import useBreakpoint from 'hooks/useBreakpoint'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  padding: 80px 0;

  ${media.lg.min} {
    padding: 80px 0 100px;
  }
`

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }
`

const ItemWrapper = styled.div<{ headings?: number }>`
  max-width: 800px;

  &:not(&:last-child) {
    margin-bottom: 45px;
  }

  ${media.lg.min} {
    &:not(&:last-child) {
      margin-bottom: 80px;
    }
  }

  ${media.lg.max} {
    padding: 0 30px;
  }

  ${({ headings }) =>
    headings &&
    headings > 1 &&
    css`
      ${media.lg.max} {
        padding: 0 0 0 30px;
      }
    `}
`

const InfoWrapper = styled.div`
  ${media.lg.max} {
    overflow-x: auto;
  }
`

const Row = styled.div<{ columns?: number }>`
  display: grid;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.lightgray};

  &:first-of-type {
    background-color: ${({ theme }) => theme.colors.lightgray};
  }

  ${({ columns }) =>
    columns &&
    css`
      grid-template-columns: repeat(${columns}, 1fr);
    `}

  ${({ columns }) =>
    columns &&
    columns > 1 &&
    css`
      ${media.lg.max} {
        width: fit-content;
        grid-template-columns: repeat(${columns}, 200px);
        gap: 50px;
      }
    `}
`

const Col = styled.div``

const ImageWrapper = styled.div`
  width: 100%;
  height: 180px;
  margin-top: 20px;

  ${media.lg.min} {
    height: 350px;
    margin-top: 40px;
  }
`

type Props = {
  extras: Array<{
    name: string
    image: {
      src: ImageDataLike
      alt: string
    }
    headings: string[]
    elements: Array<{ values: string[] }>
  }>
}

const Extras: React.FC<Props> = ({ extras }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <StyledContainer>
        {extras?.map(({ name, image, headings, elements }) => (
          <ItemWrapper headings={headings?.length}>
            <Heading
              as="h2"
              size={lg ? 30 : 22}
              sizeDiff={0}
              margin={lg ? '20px' : '25px'}
              dangerouslySetInnerHTML={{ __html: name }}
            />
            <InfoWrapper>
              <Row columns={headings?.length}>
                {headings?.map((heading) => (
                  <Col>
                    <Text
                      size={lg ? 18 : 16}
                      weight={700}
                      dangerouslySetInnerHTML={{ __html: heading }}
                      margin="0"
                    />
                  </Col>
                ))}
              </Row>
              {elements?.map((element) => (
                <Row columns={headings?.length}>
                  {element?.values?.map((value) => (
                    <Col>
                      <Text
                        size={lg ? 18 : 16}
                        weight={600}
                        dangerouslySetInnerHTML={{ __html: value }}
                        margin="0"
                      />
                    </Col>
                  ))}
                </Row>
              ))}
            </InfoWrapper>
            {image?.src && (
              <ImageWrapper>
                <LazyImage src={image?.src} alt={image?.alt} fill />
              </ImageWrapper>
            )}
          </ItemWrapper>
        ))}
      </StyledContainer>
    </Wrapper>
  )
}

export default Extras
